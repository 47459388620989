import React from 'react';
import { GeneratorUnitModel, RadioButton } from "./SurveyBase";
import GeneratorSurveyRadioButtons from "./GeneratorSurveyRadioButtons";
import GeneratorSurveyDropdown from "./GeneratorSurveyDropdown";

export interface IGeneratorSurveyDropdownProps { ListField: Array<any>, Selection: string, CallBack: Function, DropdownString: string, InputName: string }
export interface IGeneratorSurveyRadioButtonsProps { Name: string, Options: Array<any>, Selection: string, CallBack: Function, CSSClass?: string }

export interface IGeneratorSurveyUnitProps { Unit: GeneratorUnitModel, CallBack: Function, Index:number, key: number }
export interface State { }

export default class GeneratorSurveyUnit extends React.Component<IGeneratorSurveyUnitProps, State>{
    
    constructor(props){
        super(props);

        this.state = { };
    }


    public render() {
        return (            
            <div>
                <div className="unitHeader">
                    <h4 className="green margin-top-10 margin-right-10">{this.props.Unit.unitName}</h4>
                    <h5 className="blue margin-top-10">Common Name: {this.props.Unit.commonName}</h5>
                </div>
                <div className="form-group row">
                    <div className="col-7">Does the Resource have a plan to prepare for winter?</div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <GeneratorSurveyRadioButtons Name={"genUnits." + this.props.Unit.unitName.toString() + ".hasResourcePlanForWinter"} Options={[{ text: "Yes", value: true, selected: "" }, { text: "No", value: false, selected: "" }]} Selection={this.props.Unit.hasResourcePlanForWinter} CallBack={this.props.CallBack.bind(this)} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-7">What ambient air temperature can the plant reliably operate at for an extended period of time, i.e. &GT;24 hours. Please provide your best estimate based on design temperature, historical operating temperature or current cold weather performance temperature determined by an engineering analysis. </div>

                </div>
                <div className="form-group row">
                    <div className="col-6">
                        <GeneratorSurveyRadioButtons Name={"genUnits." + this.props.Unit.unitName.toString() + ".knowsTempSelection"} Options={[{ text: "Temperature limit is known", value: "True", selected: "" }, { text: "There is no temperature that impacts operation", value: "False", selected: "" }, { text: "Temperature limit is unknown", value: "Unknown", selected: "" }]} Selection={this.props.Unit.knowsTempSelection} CallBack={this.props.CallBack.bind(this)} CSSClass={"KnowsTempSelection"} />
                    </div>
                </div>
                {
                    this.props.Unit.knowsTempSelection == "True" ?
                        <div className="form-group row">
                            <span className="col-1 red" style={{ width: "0%" }}></span>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                        Temperature
                                    </div>
                                    <div className="col-6">
                                        <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                        Units
                                    </div>
                                    <div className="col-6">
                                        <input className="form-control" data-val-required="This question is required!" name={"genUnits." + this.props.Unit.unitName.toString() + ".winterizedTemp"} value={this.props.Unit.winterizedTemp ? this.props.Unit.winterizedTemp:""} onChange={this.props.CallBack.bind(this)} type="number" min="-70" max="50"/>
                                    </div>
                                    <div className="col-6">
                                        <GeneratorSurveyDropdown ListField={this.props.Unit.tempTypeList} Selection={this.props.Unit.tempTypeSelection} CallBack={this.props.CallBack.bind(this)} DropdownString={"Select Unit"} InputName={"genUnits." + this.props.Unit.unitName.toString() + ".tempTypeSelection"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <span></span>
                }
                <div className="form-group row">
                    <div className="col-7">Below what ambient temperature (&deg;F) is it expected that this Resource would no longer be able to start? Please provide your best estimate based on design temperature, historical start-up temperature or current cold weather performance temperature determined by an engineering analysis. <a href="#" role="tooltip" title="Note: NERC has made updates to two of the standards (IRO-010 and TOP-003) which require reporting of generating units minimum design temperature, historical operating temperature, or current cold weather performance temperature determined by an engineering analysis.">[?]</a></div>
                    
                </div>
                <div className="form-group row">
                    <div className="col-6">
                        <GeneratorSurveyRadioButtons Name={"genUnits." + this.props.Unit.unitName.toString() + ".knowsMinimumAmbientTempSelection"} Options={[{ text: "Temperature limit is known", value: "True", selected: "" }, { text: "There is no temperature that impacts operation", value: "False", selected: "" }, { text: "Temperature limit is unknown", value: "Unknown", selected: "" }]} Selection={this.props.Unit.knowsMinimumAmbientTempSelection} CallBack={this.props.CallBack.bind(this)} CSSClass={"KnowsTempSelection"} />
                    </div>
                </div>
                {
                    this.props.Unit.knowsMinimumAmbientTempSelection == "True" ?
                        <div className="form-group row">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                        Temperature
                                    </div>
                                    <div className="col-6">
                                        <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                        Units
                                    </div>
                                    <div className="col-6">
                                        <input className="form-control" data-val-required="This question is required!" name={"genUnits." + this.props.Unit.unitName.toString() + ".minimumAmbientTemp"} value={this.props.Unit.minimumAmbientTemp ? this.props.Unit.minimumAmbientTemp : ""} onChange={this.props.CallBack.bind(this)} type="number" min="-70" max="50" />
                                    </div>
                                    <div className="col-6">
                                        <GeneratorSurveyDropdown ListField={this.props.Unit.minimumAmbientTempUnitList} Selection={this.props.Unit.minimumAmbientTempUnit} CallBack={this.props.CallBack.bind(this)} DropdownString={"Select Unit"} InputName={"genUnits." + this.props.Unit.unitName.toString() + ".minimumAmbientTempUnit"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <span></span>
                }
                <div className="form-group row">
                    <div className="col-7">Has plant management and/or maintenance personnel reviewed the current NERC Guideline Generator Unit Winter Weather Readiness - Current Industry Practices? <a href="#" role="tooltip" title="">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <GeneratorSurveyRadioButtons Name={"genUnits." + this.props.Unit.unitName.toString() + ".reviewedNERCGuidelines"} Options={[{ text: "Yes", value: true, selected: "" }, { text: "No", value: false, selected: "" }]} Selection={this.props.Unit.reviewedNERCGuidelines} CallBack={this.props.CallBack.bind(this)} />
                    </div>
                </div>
                <div className="form-group row">
                </div>
                <div className="form-group row">
                    <div className="col-7">Is there a corporate or plant procedure for an extreme cold weather event? <a href="#" role="tooltip" title="">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <GeneratorSurveyRadioButtons Name={"genUnits." + this.props.Unit.unitName.toString() + ".procedureForExtremeWeather"} Options={[{ text: "Yes", value: true, selected: "" }, { text: "No", value: false, selected: "" }]} Selection={this.props.Unit.procedureForExtremeWeather} CallBack={this.props.CallBack.bind(this)} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-7">
                        Fuel Type
                    </div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <input className="form-control" disabled data-val-required="This question is required!" name="fuelType" value={this.props.Unit.fuelType ? this.props.Unit.fuelType: ""} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-7">
                        MPAbbreviation
                    </div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <input className="form-control" disabled data-val-required="This question is required!" name="mpAbbreviation" value={this.props.Unit.mpAbbreviation ? this.props.Unit.mpAbbreviation:""} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-7">
                        Max Output
                    </div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <input className="form-control" disabled data-val-required="This question is required!" name="fuelType" value={this.props.Unit.maxOutput ? this.props.Unit.maxOutput:""} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-7">
                        Unit Type
                    </div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-4">
                        <input className="form-control" disabled data-val-required="This question is required!" name="unitType" value={this.props.Unit.unitType ? this.props.Unit.unitType:""} />
                    </div>
                </div>
            </div>
        );
    }
}