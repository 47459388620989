module.exports = {
    Accordion: require('./Accordion/Accordion'),
    AlertSubscriptionList: require('./Alerts/AlertSubscriptionList'),
    BreadCrumbs: require('./BreadCrumbs/BreadCrumbs'),
    CallToActionLink: require('./CallToActionLink/CallToActionLink'),                                                                
    CallToActionSideBar: require('./CallToActionSideBar/CallToActionSideBar'),
    CDNDocument: require('./CDNDocument/CDNDocument'),
    ClickableImageBlocks: require('./ClickableImageBlocks/ClickableImageBlocks'),
    ContentAlerts: require('./ContentAlerts/ContentAlertList'),
    DocumentNavigator: require('./DocumentNavigator/DocumentNavigator'),
    //Editorial: require('./Editorial/Editorial'),
    //EditorialLinkList: require('./EditorialLinkList/EditorialLinkList'),
    EventCalendar: require('./EventCalendar/EventCalendar'),
    EventList: require('./EventList/EventList'),
    FeedbackResultsDownload: require('./FormResults/FeedbackResultsDownload'),
    GeneratorSurvey: require('./GeneratorSurvey/GeneratorSurveyTable'),
    GIQueueDocumentApp: require('./GIQueue/GIQueueDocumentApp'),
    GlobalAlert: require('./GlobalAlert/GlobalAlert'),
    HeroHeader: require('./HeroHeader/HeroHeader'),
    HeroButton: require('./HeroButtons/HeroButton'),
    HeroButtons: require('./HeroButtons/HeroButtons'),
    InfoBox: require('./InfoBox/InfoBox'),
    InfoBoxWithImageHeader: require('./InfoBox/InfoBoxWithImageHeader'),
    InfoBoxWithThumbnail: require('./InfoBox/InfoBoxWithThumbnail'),
    LeadershipBlock: require('./LeadershipBlock/LeadershipBlock'),
    Legend: require('./Legend/Legend'),
    MailingList: require('./MailingList/MailingList'),
    Meetings: require('./MeetingList/Meetings'),
    MISOMatters: require('./MISOMatters/MISOMatters'),
    NewsOverview: require('./NewsOverview/NewsOverview'),
    NotificationList: require('./NotificationList/NotificationList'),    
    QBankDocument: require('./QBankDocument/QBankDocument'),
    RecentNotificationList: require('./RecentNotificationList/RecentNotificationList'),
    RegistrationStatus: require('./RegistrationStatus/RegistrationStatus'),
    RelatedPages: require('./RelatedPages/RelatedPages'), //Occurances need to be converted before removal
    StakeholderFeedbackList: require('./StakeholderFeedbackList/StakeholderFeedbackList'),
    MCSNotification: require('./MCSNotification/MCSNotification'),
    RelatedDocuments: require('./RelatedDocuments/RelatedDocumentComponents'),
    Search: require('./Search/Search'),
    SearchNavigation: require('./SearchNavigation/SearchNavigation'),
    SimpleCallToActionBlock: require('./SimpleCallToActionBlock/SimpleCallToActionBlock'),
    SnapshotDashboard: require('./SnapshotDashboard/SnapshotDashboard'),
    //StakeholderFeedbackFormResults: require('./FormResults/StakeholderFeedbackFormResults'),
    Tariff: require('./Tariff/Tariff'),
    TwitterFeed: require('./TwitterFeed/TwitterFeed'),
    UserRoleList: require('./UserRoleList/UserRoleList'),
    ProfileDataModal: require('./ProfileDataModal/ProfileDataModal'),
};
