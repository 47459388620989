import React from 'react';

export interface IGeneratorSurveyCheckboxProps { ListField: Array<any>, Selection: Array<any>, InputName?: string, CallBack: Function, ShowOnlySelected: boolean }
export interface State { }

export default class GeneratorSurveyCheckbox extends React.Component<IGeneratorSurveyCheckboxProps, State>{
    
    constructor(props){
        super(props);
        this.state = { };
    }

    public render() {
        return (            
            <div className={this.props.ListField.length >= 10 ? "checkboxes doubleColumn" : "checkboxes"}>
                {
                    this.props.ListField
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                        .map((box, i) => (
                        (this.props.ShowOnlySelected && box.isVisible) || !this.props.ShowOnlySelected ?
                        (
                            <div key={i} className="checkboxAndLabel">
                                <input type="checkbox" name={this.props.InputName} value={box.id} onChange={this.props.CallBack.bind(this)} checked={box.isSelected}/>
                                <label>{box.name}</label>
                            </div>
                        ) : null
                    ))
                }
            </div>   
        );
    }
}