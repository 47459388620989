import React from 'react';
import { OutlookBox } from "./OutlookBox";

interface ISnapshotDashboardProps {
    service_url: string,
    title: string,
}

interface State {
    snapshots: Array<any>;
    hasError: boolean;
    isLoaded: boolean;
}

export class SnapshotDashboard extends React.Component<ISnapshotDashboardProps, State> {
    constructor(props) {
        super(props);
        this.state = { snapshots: [], hasError: false, isLoaded: false };
    }

    componentDidMount() {
        this.getSnapshots();
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    getSnapshots() {
        var serviceUrl = this.props.service_url;
        $.ajax({
            url: serviceUrl,
            type: "GET", 
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers": "content-type"

            },
            success: function (data) {
                this.setState({ snapshots: data, isLoaded:true });
            }.bind(this)
        });
    }

    private getAsOfDate() {
        var latestDate = this.state.snapshots[0]["d"];

        this.state.snapshots.map((snapshot, i) => {
            if (Date.parse(snapshot["d"]) > Date.parse(latestDate)) {
                latestDate = snapshot["d"];
            }
        });

        return latestDate;
    }

    render() {
        
        if (!this.state.isLoaded) {
            return <div></div>
        }

        if (this.state.isLoaded && !this.state.hasError && this.state.snapshots.length > 0) {
            const asOf = this.getAsOfDate();

            return (
                <div className="outlook-container">
                    <div className="outlook-container-title col-12">
                        <div className="outlook-title">{this.props.title}</div>
                        <div className="update-time"> {asOf}</div>
                    </div>
                    <div className="snapShots">
                    {
                        this.state.snapshots.map((snapshot, i) => {
                            return <OutlookBox key={i} box={this.state.isLoaded ? snapshot : null}/>;
                        })
                        }
                    </div>
                </div>
            );
        } else {
            const today = new Date().toUTCString();
            var b = new OutlookBox({"t":"--"});

            return (
                <div className="outlook-container">
                    <div className="outlook-container-title col-12">
                        {this.props.title}
                        <div className="update-time"> as of {today}</div>
                    </div>

                    <OutlookBox box={b}/>
                </div>
            );
        }
    }
}
