import React from 'react';

export interface IGeneratorSurveyRadioButtonsProps {Name:string, Options: Array<any>, Selection:any, CallBack:Function, CSSClass?:string }
export interface State { }

export default class GeneratorSurveyRadioButtons extends React.Component<IGeneratorSurveyRadioButtonsProps, State>{
    
    constructor(props){
        super(props);
        this.state = { };
    }

    public render() {
        return (            
            <div className="radios">
            {
                this.props.Options.map((radio, i) => {
                    {
                        return (
                            <span className={this.props.CSSClass ? this.props.CSSClass : ""} key={i}>
                                
                                <input name={this.props.Name} type="radio" defaultValue={radio.value} onChange={this.props.CallBack.bind(this)} checked={radio.value == this.props.Selection ? true:false} required/>
                                <label>{radio.text}</label>
                            </span>
                        )
                    }
                })
            }

            </div>   
        );
    }
}