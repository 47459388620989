import React from 'react';

export interface IGeneratorSurveyDropdownProps { ListField:Array<any>, Selection:string, CallBack:Function, DropdownString:string, InputName:string }
export interface State { }

export default class GeneratorSurveyDropdown extends React.Component<IGeneratorSurveyDropdownProps, State>{
    
    constructor(props){
        super(props);
        this.state = { };
    }
    
    public render() {
        return (            
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.props.Selection ? this.props.Selection : this.props.DropdownString} <i className="fa fa-caret-down pull-right" aria-hidden="true"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button className="btn btn-link dropdown-item" name={this.props.InputName} value={" "} onClick={this.props.CallBack.bind(this)}> </button>
                    {
                        this.props.ListField.map((listItem, i) => {
                            {
                                return (
                                    //<div>{lba.text}</div>
                                    <button key={i} className="btn btn-link dropdown-item" type="button" name={this.props.InputName} value={listItem.value} onClick={this.props.CallBack.bind(this)} >{listItem.text}</button>
                                )
                            }
                        })
                    }
                </div>
            </div>   
        );
    }
}