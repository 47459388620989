// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import * as $ from "jquery";
import Moment from 'moment';
import * as secured from '../../secured';
import {GeneratorSurveySelectionModel } from './SurveyBase';
import {GeneratorSurvey} from "./GeneratorSurvey";

export interface IGeneratorSurveyProps { SurveyId: string, DeadlineDate: string, YearRange: string, SurveyKey: string, SuccessfulKey: Boolean }

interface IGeneratorSurveyTableProps { YearRange:string, DeadlineDate:string, StartInstructions:string} //SurveyId:string }

interface State {
    SurveyKey: string;
    Loaded: Boolean;
    Submitted: Boolean;
    Surveys: Array<GeneratorSurveySelectionModel>;
    KeySurveys: Array<GeneratorSurveySelectionModel>;
    ShowSurvey: Boolean;
    SurveyId: string;
    SuccessfulKey: Boolean;
}

export class GeneratorSurveyTable extends React.Component<IGeneratorSurveyTableProps, any>{
    constructor(props) {
        super(props);
        this.state = { SurveyKey:'', Loaded:false, Submitted:false, Surveys:[], KeySurveys:[], ShowSurvey:false, SurveyId:'', SuccessfulKey:false};
    }
    public componentDidMount() {
        this.getSurveys();
    }
    public handleChange(event) {
        var newValue = event.target.value;
        this.setState({ SurveyKey:newValue.toString() });
    }
    public getSurveys() {
        return $.getJSON("/api/GeneratorSurveyReact/GetSurveys",
            function (data) {
                if (this.state.KeySurveys.length > 0) {
                    for (var i = 0; i < this.state.KeySurveys.length; i++) {
                        data.push(this.state.KeySurveys[i]);
                    }
                }
                this.setState({ Surveys: data, Loaded: true });
            }.bind(this));
    }
    public submitKey(event) {
        event.preventDefault();
        this.setState({ Loaded: false });
        return $.getJSON("/api/GeneratorSurveyReact/GetSurveysByKey?surveyKey=" + this.state.SurveyKey,
            function (data) {
                if (data.length > 0) {
                    var surveys = this.state.Surveys;
                    for (var i = 0; i < data.length; i++) {
                        surveys.push(data[i]);
                    }
                    this.setState({ SuccessfulKey: true, Surveys:surveys });
                }

                this.setState({ KeySurveys: data, Loaded: true });
            }.bind(this));
        
    }
    public selectSurvey(surveyId) {
        if (!secured.Secured.LoggedIn) {
            secured.Secured.Action([], { secured: true });
        } else {
            this.setState({ ShowSurvey: true, SurveyId: surveyId });
        }
    }
    public submittedSurvey() {
        this.setState({ ShowSurvey: false, SurveyId: '', Submitted: true });
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (!secured.Secured.LoggedIn) {
            this.refreshPage();
        } 
        this.getSurveys();

    }
    public cancelledSurvey() {

        this.setState({ ShowSurvey: false, SurveyId: '', Submitted: false });
        window.scrollTo({top: 0,behavior: "smooth"});
    }
    public refreshPage() {
        window.location.reload();
    }
    submitHandler(e) {
        e.preventDefault();
    }
    public render() {
        return (
            this.state.ShowSurvey ?
                <GeneratorSurvey SurveyId={this.state.SurveyId} SubmittedCallback={this.submittedSurvey.bind(this)} CancelCallback={this.cancelledSurvey.bind(this)} DeadlineDate={this.props.DeadlineDate} YearRange={this.props.YearRange} SurveyKey={this.state.SurveyKey} SuccessfulKey={this.state.SuccessfulKey}/>
                :

                <div className='WinterSurveyAsReactComp'>
                    <h2 className="green-bg">MISO {this.props.YearRange} Generation Winterization Survey</h2>
                    {
                        this.state.Submitted ?
                            <div className="alertRow">
                                <i className="fa fa-check"></i>
                                Your survey was submitted successfully!
                            </div>
                            : <span></span>
                    }
                    <span dangerouslySetInnerHTML={{ __html: this.props.StartInstructions }}></span>
                    <div className="margin-btm-10">Start with blank Survey: <a className="btn btn-link" href="#" onClick={() => { this.selectSurvey("-1"); }}>Select</a></div>
                    <form onSubmit={this.submitKey.bind(this)}>
                        <div className="form-group companyRow">
                            <div className="companyTitle">Company Survey Key:</div>
                            <div className="col-3">
                                <input className="form-control" data-val-required="This question is required!" name="SurveyKey" value={this.state.SurveyKey} onChange={this.handleChange.bind(this)} required />
                                {this.state.SuccessfulKey ? <i className="form-control-feedback glyphicon glyphicon-ok" data-fv-icon-for="SuccessfulKey"></i> : <span></span>}
                            </div>
                            <div className="">
                                {this.state.SuccessfulKey ? <button className="btn btn-small btn-primary" type="submit" disabled>Submit</button> :
                                    <button className="btn btn-small btn-primary" type="submit">Submit</button>}
                            </div>
                        </div>

                    </form>
                    <div>Start survey with available populated information:</div>
                    {
                        this.state.Loaded ?

                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th className="text-center">Action</th>
                                        <th>LBA</th>
                                        <th>Station</th>
                                        <th>Plant Name</th>
                                        <th>Submission Date</th>
                                        <th>Submitted By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Surveys.length > 0 ?
                                            this.state.Surveys
                                                .sort((a, b) => Moment(new Date(a.dtAdded), "M/D/YYYY h:mm A", true) < Moment(new Date(b.dtAdded), "M/D/YYYY h:mm A", true) ? 1 : -1).map((survey, i) => (
                                                <tr key={i}>
                                                    <td className="text-center"><a className="btn btn-link" href="#" onClick={() => { this.selectSurvey(survey.surveyId); }}>{survey.surveyId}</a></td>
                                                    <td>{survey.lbaSelection}</td>
                                                    <td>{survey.stationSelection}</td>
                                                    <td>{survey.plantName}</td>
                                                    <td>{survey.dtAdded}</td>
                                                    <td>{survey.userEmail}</td>
                                                </tr>
                                            ))
                                            : <tr><td>No previously saved surveys available</td></tr>
                                    }
                                </tbody>
                            </table>
                            : <div className="winterSurveySpinner text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></div>
                    }

                </div>
            
        )
    }
}