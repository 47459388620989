import React from 'react';
import { SurveyItem } from './SurveyBase';
import { GeneratorUnitModel, RadioButton } from "./SurveyBase";
import GeneratorSurveyRadioButtons from "./GeneratorSurveyRadioButtons";
import GeneratorSurveyDropdown from "./GeneratorSurveyDropdown";
import GeneratorSurveyCheckbox from "./GeneratorSurveyCheckbox";

export interface IGeneratorSurveyRadioButtonsProps { Name: string, Options: Array<any>, Selection: string, CallBack: Function, CSSClass?: string }
export interface IGeneratorSurveyDropdownProps { ListField: Array<any>, Selection: string, CallBack: Function, DropdownString: string, InputName: string }
export interface IGeneratorSurveyCheckboxProps { ListField: Array<any>, Selection: Array<any>, InputName: string, CallBack: Function, ShowOnlySelected: boolean }


export interface IGeneratorSurveyGasSpecificProps { Survey: SurveyItem, CallBack: Function, NestedCallBack:Function }
export interface State { }

export default class GeneratorSurveyGasSpecific extends React.Component<IGeneratorSurveyGasSpecificProps, State>{
    
    constructor(props){
        super(props);

        this.state = { };
    }


    public render() {
        return (            
            <div>
                <div className="form-group row">
                    <div className="col-5">Select the Interstate or Intrastate pipeline(s) that is directly connected to the Station. If not applicable or not listed, select 'None'. If connected to multiple sources and one is not listed, select what is available and proceed with the Survey. A subsequent question will provide the opportunity to detail sources not listed. <a href="#" role="tooltip" title="Check all that apply.  A subsequent question will address any sources not provided as an option.">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-6">
                        <GeneratorSurveyCheckbox ListField={this.props.Survey.availablePipelines} Selection={this.props.Survey.availablePipelines} InputName={"availablePipelines"} CallBack={this.props.NestedCallBack.bind(this)} ShowOnlySelected={false} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-5">Select the Local Distribution Company (LDC) or Gas utility that is directly connected to the Station. If not applicable or not listed, select 'None'. If connected to multiple sources and one is not listed, select what is available and proceed with the Survey. A subsequent question will provide the opportunity to detail sources not listed. <a href="#" role="tooltip" title="Check all that apply.  A subsequent question will address any sources not provided as an option.">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-6">
                        <GeneratorSurveyCheckbox ListField={this.props.Survey.unitLdcNames} Selection={this.props.Survey.unitLdcNames} InputName={"unitLdcNames"} CallBack={this.props.NestedCallBack.bind(this)} ShowOnlySelected={false} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-5">
                        If applicable, what other gas system is the Station directly connect to that was not previously listed? <a href="#" role="tooltip" title="If your fuel source was not previuosly listed or identified (check box options), please provide the name of the source so that MISO's database can be updated.">[?]</a>
                    </div>
                    <span className="col-1 red" style={{ width: "0%" }}></span>
                    <div className="col-6">
                        <input className="form-control" onChange={this.props.CallBack.bind(this)} name="otherPipelines" value={this.props.Survey.otherPipelines ? this.props.Survey.otherPipelines: ""}/>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-5">
                        (Optional) If known and applicable, what is the primary upstream supply pipeline for the LDC? (What pipeline influences your Station or LDC Operations the most?) <a href="#" role="tooltip" title="What pipeline influences your Station or LDC Operations the most?">[?]</a>
                    </div>
                    <span className="col-1 red" style={{ width: "0%" }}></span>
                    <div className="col-6">
                        <GeneratorSurveyCheckbox ListField={this.props.Survey.availablePrimaryUpstreamPipelines} Selection={this.props.Survey.availablePrimaryUpstreamPipelines} InputName={"availablePrimaryUpstreamPipelines"} CallBack={this.props.NestedCallBack.bind(this)} ShowOnlySelected={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-5">What type of Transportation is used to serve this Station? <a href="#" role="tooltip" title="If supply is 'Delivered' or transported by 3rd party, select the service you believe is used.  Firm includes primary, secondary, callable, delivered Firm.">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-6">
                        <GeneratorSurveyDropdown ListField={this.props.Survey.transportationTypeList} Selection={this.props.Survey.transportationTypeSelection} InputName="transportationTypeSelection" DropdownString={"Select Transportation Type "} CallBack={this.props.CallBack.bind(this)} />
                    </div>
                </div>
                {
                    this.props.Survey.transportationTypeSelection == "Firm Transport Only" ?
                        <div className="form-group row">
                            <div className="col-5">Select the source on which the Station has access to Firm Transportation <a href="#" role="tooltip" title="Check all that apply. If 'None' was selected for both Interstate/Intrastate pipeline(s) and LDC selections then leave blank.">[?]</a></div>
                            <span className="col-1 red" style={{ width: "0%" }}>*</span>
                            <div className="col-6">
                                <GeneratorSurveyCheckbox ListField={this.props.Survey.availableFirmTransportPipelines} Selection={this.props.Survey.availableFirmTransportPipelines} InputName={"availableFirmTransportPipelines"} CallBack={this.props.NestedCallBack.bind(this)} ShowOnlySelected={true} />
                            </div>
                        </div>
                    :<div></div>
                }
                {
                    this.props.Survey.availableFirmTransportPipelines.some(element => {
                        if (element.isSelected == true && this.props.Survey.transportationTypeSelection == "Firm Transport Only") {
                            return true
                        } else {
                            return false
                        }
                    }) ?
                        <div className="form-group row">
                            <div className="col-5">Is Firm Transportation shared with other generator stations/facilities in your portfolio? <a href="#" role="tooltip" title="This is likely applicable to Market Participants who have Firm Contracts that can deliver to multiple Stations on a single pipeline. i.e. Firm Transportation across a fleet of assets or Firm deliveries split amongst multiple locations.">[?]</a></div>
                            <span className="col-1 red" style={{ width: "0%" }}>*</span>
                            <div className="col-6">
                                <GeneratorSurveyRadioButtons Name={"firmTransportSharedSelection"} Options={[{ text: "Yes", value: "True", selected: "" }, { text: "No", value: "False", selected: "" }, { text: "Unknown", value: "Unknown", selected: "" }]} Selection={this.props.Survey.firmTransportSharedSelection} CallBack={this.props.CallBack.bind(this)} />
                            </div>
                        </div>
                        :<div></div>
                    
                }
                {
                    this.props.Survey.transportationTypeSelection == "Firm and Interruptible Combination" ?
                        <div className="form-group row">
                            <div className="col-5">How many hours would Firm Transportation Maximum Daily Quantity (MDQ) cover at max generation output? (assume all units on the site are at max output) <a href="#" role="tooltip" title="Example: If station max hourly burn is 2,000 Mmbtu/hr and FT contract is for 10,000 Mmbtu, run time on FT would be 5 hours. (For this question, assume FT contract would be used fully at this site and not shared with other stations in the portfolio).">[?]</a></div>
                            <span className="col-1 red" style={{ width: "0%" }}>*</span>
                            <div className="col-6">
                                <input className="form-control" onChange={this.props.CallBack.bind(this)} name="hoursSharedTransportMdq" value={this.props.Survey.hoursSharedTransportMdq ? this.props.Survey.hoursSharedTransportMdq : ""} type="number" min="0" max="50"/>
                            </div>
                        </div>
                        : <div></div>
                }
                <div className="form-group row">
                    <div className="col-5">Does the Station have a No-Notice Service? i.e., a service that allows the generator to come online prior to gas being nominated. Or, the ability to pull gas after nomination cycles have closed. <a href="#" role="tooltip" title="i.e., a service that allows the generator to come online prior to gas being nominated. Or, the ability to pull gas after nomination cycles have closed.">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-6">
                        <GeneratorSurveyRadioButtons Name="noNoticeServiceSelection" Options={[{ text: "Yes", value: "True", selected: "" }, { text: "No", value: "False", selected: "" }, { text: "Unknown", value: "Unknown", selected: "" }]} Selection={this.props.Survey.noNoticeServiceSelection} CallBack={this.props.CallBack.bind(this)} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-5">Does the Station have a Non-Ratable Take or Batch Service? i.e., a service that allows the generator to pull gas outside of a 1/24th take. For example, if a plant is only needed for 6 hours and thus consumes gas over these 6 hours instead of 24, is hourly swing flexibility available? <a href="#" role="tooltip" title="i.e., a service that allows the generator to pull gas outside of a 1&#4724th take.  For example, if a generator is only needed for 6 hours and thus consumes gas over these 6 hours instead of 24, is hourly swing flexibility available?">[?]</a></div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-6">
                        <GeneratorSurveyRadioButtons Name="batchServiceSelection" Options={[{ text: "Yes", value: "True", selected: "" }, { text: "No", value: "False", selected: "" }, { text: "Unknown", value: "Unknown", selected: "" }]} Selection={this.props.Survey.batchServiceSelection} CallBack={this.props.CallBack.bind(this)}/>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-5">Does the Station subscribe to a Storage Service?</div>
                    <span className="col-1 red" style={{ width: "0%" }}>*</span>
                    <div className="col-6">
                        <GeneratorSurveyRadioButtons Name="storageServiceSelection" Options={[{ text: "Yes", value: "True", selected: "" }, { text: "No", value: "False", selected: "" }, { text: "Unknown", value: "Unknown", selected: "" }]} Selection={this.props.Survey.storageServiceSelection} CallBack={this.props.CallBack.bind(this)} />
                    </div>
                </div>
            </div>
        );
    }
}