import React from 'react';
import * as toast from '../../toast';
import * as dateFormatter from '../../dateFormatter';
import { Multiselect } from '../Multiselect/Multiselect';


interface IProfileDataModalProps {
    profile: Array<any>;
    roleList: Array<RoleInformation>;
    isAdmin: boolean;
    isITOCUser: boolean;
    isClientRelationsEditor: boolean;
    isStakeholderRelationsEditor: boolean;
}
class RoleInformation {
    roleName: string;
    friendlyName: string;
    extranetRole: boolean;
    gridUnityRole: boolean;
    adminOnlyRole: boolean;
}

interface State {
    showModal: boolean;
    loading: boolean;
    showAccessApprovals: boolean;
    approveRole: string;
    approvedRoles: Array<any>;
    updatedRoleNames: Array<string>;
}

export class ProfileDataModal extends React.Component<IProfileDataModalProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            loading: true,
            showAccessApprovals: false,
            approveRole: "approve",
            approvedRoles: [],
            updatedRoleNames: []
        }

        this.updateNewRolesList = this.updateNewRolesList.bind(this);
    }

    public componentDidMount() {
        this.GetUserRoles();
    }

    public GetUserRoles() {
        $.getJSON("/ProfileLookupPage/GetUserAccessStatus?userEmail=" + encodeURIComponent(this.props.profile['email']),
            function (data) {
                this.nullCheck(data);
                data.roles = data.roles.sort();
                this.setState({ approvedRoles: data.roles, loading: false });
            }.bind(this));
    }

    private updateNewRolesList(updatedRoleNames: Array<string>) {
        this.setState({ updatedRoleNames });
    }

    private grantAccess() {
        $.ajax({
            type: 'post',
            data: JSON.stringify({
                Email: this.props.profile['email'],
                Roles: this.state.updatedRoleNames
            }),
            url: "/ProfileLookupPage/GrantUserRoles",
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                this.nullCheck(data);
                this.GetUserRoles(this.props.profile['email']);
                toast.Toast.Display("Access for " + this.props.profile['firstname'] + " " + this.props.profile['lastname'] + " has been granted.", "Granted");
            }.bind(this)
        });
    }

    private revokeAccess() {
        var comments = document.querySelector(`input[name="revokeComments"]`);

        if (comments["value"] == null || comments["value"] == "") {
            alert("Comments are required when revoking access.");
            return;
        }

        $.ajax({
            type: 'post',
            data: JSON.stringify({
                Email: this.props.profile['email'],
                Roles: this.state.updatedRoleNames,
                Comment: comments["value"]
            }),
            url: "/ProfileLookupPage/RevokeUserRoles",
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                this.nullCheck(data);
                this.GetUserRoles(this.props.profile['email']);
                toast.Toast.Display("Access for " + this.props.profile['firstname'] + " " + this.props.profile['lastname'] + " has been revoked.", "Revoked", "error");
            }.bind(this)
        });
        document.querySelector(`input[name="revokeComments"]`)["value"] = "";
        
    }

    private unlockProfile() {
        $.ajax({
            type: 'post',
            data: JSON.stringify({
                Email: this.props.profile['email']
            }),
            url: "/ProfileLookupPage/UnlockUser",
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                this.GetUserRoles(this.props.profile['email']);
                this.props.profile['islockedout'] = !data;
                var statusMessage = (data ? "Successfully unlocked profile for " : "Failed to unlock profile for ") + this.props.profile['email']
                toast.Toast.Display(statusMessage, data ? "Profile Unlocked" : "Profile Unlock Failed");
            }.bind(this)
        });
    }

    private confirmUser() {
        $.ajax({
            type: 'post',
            data: JSON.stringify({
                Email: this.props.profile['email']
            }),
            url: "/ProfileLookupPage/ConfirmUser",
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                this.GetUserRoles(this.props.profile['email']);
                this.props.profile['isactive'] = data;
                this.props.profile['isconfirmed'] = data;
                var statusMessage = (data ? "Confirmed and activated user: " : "Failed to confirm and/or activate user: ") + this.props.profile['email']
                toast.Toast.Display(statusMessage, data ? "User Confirmed" : "User Confirmation Failed");
            }.bind(this)
        });
    }

    private resendConfirmationEmail() {
        $.ajax({
            type: 'post',
            data: JSON.stringify({
                Email: this.props.profile['email']
            }),
            url: "/ProfileLookupPage/ResendConfirmationEmail",
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                this.GetUserRoles(this.props.profile['email']);
                var statusMessage = (data ? "Resent confirmation email to " : "Failed to resend confirmation email to ") + this.props.profile['email']
                toast.Toast.Display(statusMessage, data ? "Resent Confirmation Email" : "Failed to Resend Confirmation Email");
            }.bind(this)
        });
    }

    public open() {
        this.setState({
            showModal: true
        });
    }

    public close() {
        this.setState({
            showModal: false
        });
    }
    public showApprovalSection() {
        this.setState({ showAccessApprovals: true });
    }

    public hideApprovalSection() {
        this.setState({ showAccessApprovals: false });
    }
    public toggleApproveAndDeny(e) {
        var approveBoxes = document.querySelectorAll(`input[name="approveRole"]:checked`);
        for (var i = 0; i < approveBoxes.length; i++) {
            approveBoxes[i]["checked"] = false;
        }

        var rejectBoxes = document.querySelectorAll(`input[name="rejectRole"]:checked`);
        for (var i = 0; i < rejectBoxes.length; i++) {
            rejectBoxes[i]["checked"] = false;
        }

        this.setState({ approveRole: e });
    }

    private nullCheck(data) {
        if (data == "null") {
            this.close();
        }
    }

    private getAppropriateMultiselectValues(): Array<string> {
        if (this.state.approveRole === "approve") {
            return this.props.roleList
                .filter(role => this.state.approvedRoles.indexOf(role.roleName) == -1 && (this.props.isAdmin || (!this.props.isAdmin && !role.adminOnlyRole && !role.gridUnityRole)))
                .map(role => role.roleName);
        } else if (this.state.approveRole === "revoke") {
            return this.props.roleList
                .filter(role => this.state.approvedRoles.indexOf(role.roleName) > -1 && (this.props.isAdmin == true || (this.props.isAdmin == false && role.adminOnlyRole == false && role.gridUnityRole == false)))
                .map(role => role.roleName);
        }

        console.error('Invalid Approval State.');
        return new Array<string>();
    }

    public render() {
        return (
            <>
                {this.state.showModal && (
                        
                    <div className="modalBody">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-8">
                                    <table className="misoTable2">
                                        <thead>
                                            <tr>
                                                <td>User Info</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Email Address</td>
                                                <td>{this.props.profile['email']}</td>
                                            </tr>
                                            <tr>
                                                <td>First & Last Name</td>
                                                <td>{this.props.profile['firstname']} {this.props.profile['lastname']}</td>
                                            </tr>
                                            <tr>
                                                <td>Company</td>
                                                <td>{this.props.profile['companyname']}</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>{this.props.profile['streetaddress']}</td>
                                            </tr>
                                            <tr>
                                                <td>City, State</td>
                                                <td>{this.props.profile['city']}, {this.props.profile['state']}</td>
                                            </tr>
                                            <tr>
                                                <td>Zip</td>
                                                <td>{this.props.profile['zipcode']}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone Number</td>
                                                <td>{this.props.profile['phonenumber']}</td>
                                            </tr>
                                            <tr>
                                                <td>Secondary Phone Number</td>
                                                <td>{this.props.profile['secondaryphonenumber']}</td>
                                            </tr>
                                            <tr>
                                                <td>Account Status</td>
                                                {this.props.profile['isactive'] == "true" ? <td style={{ color: 'green' }}>Active</td> : <td style={{ color: 'red' }}>Not Active</td>}
                                            </tr>
                                            <tr>
                                                <td>Confirmed Status</td>
                                                {this.props.profile['isconfirmed'] == "true"
                                                    ? <td style={{ color: 'green' }}>Confirmed</td>
                                                    : <td>
                                                        <div className="row">
                                                            <div className="margin-right-5 col-auto flex-grow-0 my-auto" style={{ color: 'red' }}>Not Confirmed</div>
                                                            <div className="col justify-content-around">
                                                                {this.props.isAdmin && (
                                                                    <button className="btn btn-primary" onClick={() => { this.confirmUser(); }}>Confirm Account</button>
                                                                )}
                                                                {(this.props.isAdmin || this.props.isClientRelationsEditor) && (
                                                                    <button className="btn btn-primary" onClick={() => { this.resendConfirmationEmail(); }}>Resend Confirmation Email</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                      </td>}
                                            </tr>
                                            <tr>
                                                <td>Lock Status</td>
                                                {this.props.profile['islockedout'] == "true"
                                                    ? <td>
                                                        <div className="row">
                                                            <div className="margin-right-5 col-auto flex-grow-0 my-auto" style={{ color: 'red' }}>Locked</div>
                                                            {(this.props.isAdmin || this.props.isITOCUser) && (
                                                                <div className="col">
                                                                    <button className="btn btn-primary" onClick={() => { this.unlockProfile(); }}>Unlock Account</button>
                                                                </div>
                                                            )}
                                                        </div>
                                                      </td>
                                                    : <td style={{ color: 'green' }}>Unlocked</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Lockout End</td>
                                                <td>{dateFormatter.DateFormatter.Format(this.props.profile['lockoutend'], "M/D/YYYY HH:mm")}</td>
                                            </tr>
                                            <tr>
                                                <td>Last Lockout Date</td>
                                                <td>{dateFormatter.DateFormatter.Format(this.props.profile['lastlockoutdate'], "M/D/YYYY HH:mm")}  </td>
                                            </tr>
                                            <tr>
                                                <td>Creation Date</td>
                                                <td>{dateFormatter.DateFormatter.Format(this.props.profile['creationdate'], "M/D/YYYY HH:mm")} </td>
                                            </tr>
                                            <tr>
                                                <td>Last Login Date</td>
                                                <td>{dateFormatter.DateFormatter.Format(this.props.profile['lastlogindate'], "M/D/YYYY HH:mm")}  </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-4">
                                    <table className="misoTable2">
                                        <thead>
                                            <tr>
                                                <td>Current Access</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.approvedRoles.length > 0 ?
                                                    this.state.approvedRoles.map((sub, i) => {
                                                        return (<tr key={i}><td>{sub}</td></tr>);
                                                    })
                                                    : <tr><td>No Current Access</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="margin-top-10">
                                {(this.props.isAdmin || this.props.isClientRelationsEditor) && (
                                    this.state.showAccessApprovals ?
                                        <button className="btn btn-primary" onClick={() => { this.hideApprovalSection(); }}>Hide Access Manager<i className="fa fa-arrow-circle-right margin-left-50"></i></button> :
                                        <button className="btn btn-primary" onClick={() => { this.showApprovalSection(); }}>Show Access Manager<i className="fa fa-arrow-circle-right margin-left-50"></i></button>
                                )}
                            </div>
                            {this.state.loading == false && this.state.showAccessApprovals == true ?

                                <div className="accessApprovalArea margin-top-20">
                                    <div className="tab-content-block">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><a className={this.state.approveRole == "approve" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href='#approve' onClick={() => { this.toggleApproveAndDeny("approve"); }}>Grant</a></li>
                                            <li className="nav-item"><a className={this.state.approveRole == "revoke" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href='#revoke' onClick={() => { this.toggleApproveAndDeny("revoke"); }}>Revoke</a></li>
                                        </ul>
                                    </div>
                                    <div className="borderArea">
                                        {
                                            this.state.approveRole === "approve" ?
                                                <h3>Grant Access</h3> :
                                                <h3>Revoke Access</h3>
                                        }
                                        <div className="row">
                                            <Multiselect items={this.getAppropriateMultiselectValues()}
                                                keepOpen={true}
                                                sortColumnsAlphabetically={true}
                                                updateSelectedItems={this.updateNewRolesList} />
                                            {
                                                this.state.approveRole == "revoke" ?
                                                    <div className="col-12 margin-top-20 margin-btm-20">Reason<span className="asterick">*</span>
                                                        <input type="text" className="col-12 form-control" name="revokeComments" />
                                                        <div className="required"><span className="asterick">*</span>Indicates a required field</div>
                                                    </div> :
                                                    <span></span>

                                            }
                                        </div>
                                        <button className="btn margin-right-20" onClick={() => { this.hideApprovalSection(); }}>Cancel</button>
                                        {this.state.approveRole == "approve" ?
                                            <button className="btn btn-success margin-right-10" onClick={() => { this.grantAccess(); }}>Grant Access</button> :
                                            <button className="btn btn-negative" onClick={() => { this.revokeAccess(); }}>Revoke Access</button>}
                                    </div>

                                </div> :
                                <div></div>
                            }
                        </div>

                    </div>
                )}
            </>
            
        );

    };
}
