export class SurveyItem {
    public deadlineDate: string = null;
    public yearRange: string = null;
    public userEmail: string = null;
    public firstName: string = null;
    public lastName: string = null;
    public company: string = null;
    public phone: string = null;
    public unitNameList: Array<SelectListItem> = null;
    public email: string = null;
    public surveyKey: string = null;
    public lbaSelection: string = null;
    public lbaList: Array<SelectListItem> = null;
    public stationSelection: string = null;
    public stationList: Array<SelectListItem> = null;
    public unitNameSelection: Array<Checkbox> = null;
    public isGasSpecific: boolean = null;
    public unitNameSelectionIsCheckboxSelected: boolean = null;
    public availablePipelines: Array<PipelineCheckBox> = null;
    public otherPipelines: string = null;
    public availablePipelinesIsCheckboxSelected: boolean = null;
    public availableFirmTransportPipelinesIsRequired: boolean = null;
    public availableFirmTransportPipelinesIsCheckboxSelected: boolean = null;
    public unitLdcNames: Array<LdcCheckBox> = null;
    public unitLdcNamesIsCheckboxSelected: boolean = null;
    public primaryUpstreamPipeline: string = null;
    public availablePrimaryUpstreamPipelines: Array<GenPrimaryUpstreamPipelineCheckBox> = null;
    public transportationTypeSelection: string = null;
    public transportationTypeList: Array<SelectListItem> = null;
    public noNoticeServiceSelection: string = null;
    public noNoticeServiceList: Array<SelectListItem> = null;
    public noNoticeServiceTypeSelection: string = null;
    public availableFirmTransportPipelines: Array<FirmTransportPipelineCheckBox> = null;
    public OtherFirmTransportPipelines: string = null;
    public firmTransportSharedSelection: string = null;
    public firmTransportSharedList: Array<SelectListItem> = null;
    public batchServiceSelection: string = null;
    public batchServiceList: Array<SelectListItem> = null;
    public batchServiceTypeSelection: string = null;
    public subscribedStorageServiceSelection: Array<SelectListItem> = null;
    public storageServiceSelection: string = null;
    public storageServiceList: Array<SelectListItem> = null;
    public genUnits: Array<GeneratorUnitModel> = null;
    public hoursSharedTransportMdq: string = null;
    public hoursSharedTransportMdqList: Array<SelectListItem> = null;
}

export class SelectListItem {
    public disabled: boolean = null;
    public group: Array<any> = null;
    public selected: boolean = null;
    public text: string = null;
    public value: string = null;
    constructor(disabled, group, selected, text, value) {
        this.disabled = disabled;
        this.group = group;
        this.selected = selected;
        this.text = text;
        this.value = value;
    }
}
export class Checkbox {
    public id: string = null;
    public name: string = null;
    public isSelected: boolean = null;
}
export class PipelineCheckBox extends Checkbox{

}

export class LdcCheckBox extends Checkbox {
    public ldcMeterName: string = null;
    public ldcMeterNumber: string = null;
}
export class FirmTransportPipelineCheckBox extends Checkbox {
    public isVisable: boolean = null;
    public primaryReceiptPts: string = null;
    public firmTransportationMdqSelectList: Array<SelectListItem> = null;
}
export class GenPrimaryUpstreamPipelineCheckBox extends FirmTransportPipelineCheckBox {

}
export class GeneratorUnitModel {
    public unitNumber: string = null;
    public unitName: string = null;
    public cpNode: string = null;
    public commonName: string = null;
    public unitNameList: Array<SelectListItem> = null;
    public mpAbbreviation: string = null;
    public unitType: string = null;
    public fuelType: string = null;
    public maxOutput: string = null;
    public hasResourcePlanForWinter: boolean = null;
    public winterizedTemp: string = null;
    public reviewedNERCGuidelines: boolean = null;
    public procedureForExtremeWeather: boolean = null;
    public tempTypeSelection: string = null;
    public tempTypeList: Array<SelectListItem> = null;
    public knowsTempList: Array<SelectListItem> = null;
    public knowsTempSelection: string = null;
    public isSelected: boolean = null;
    public otherComments: string = null;
    public pmax: number = null;
    public knowsMinimumAmbientTempSelection: string = null;
    public knowsMinimumAmbientTempList: Array<SelectListItem> = null;
    public minimumAmbientTemp: string = null;
    public minimumAmbientTempUnit: string = null;
    public minimumAmbientTempUnitList: Array<SelectListItem> = null;
}
export class RadioButton {
    public text: string = null;
    public value: boolean = null
    public checked: string = null;
}
export class GeneratorSurveySelectionModel {
    public stationSelection: string = null;
    public unitNameSelection: string = null;
    public surveyId: string = null;
    public lbaSelection: string = null;
    public plantName: string = null;
    public dtAdded: Date = null;
    public dtAddedString: string = null;
    public surveyKey: string = null;
    public userName: string = null;
}
