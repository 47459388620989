// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import GeneratorSurveyDropdown from "./GeneratorSurveyDropdown";
import GeneratorSurveyCheckbox from "./GeneratorSurveyCheckbox";
import GeneratorSurveyRadioButtons from "./GeneratorSurveyRadioButtons";
import GeneratorSurveyUnit from "./GeneratorSurveyUnit";
import GeneratorSurveyGasSpecific from "./GeneratorSurveyGasSpecific";
import { SurveyItem } from './SurveyBase';
import { RadioButton } from "./SurveyBase";
import { GeneratorUnitModel } from "./SurveyBase";
import { SelectListItem } from "./SurveyBase";


export interface IGeneratorSurveyDropdownProps { ListField: Array<any>, Selection: string, CallBack: Function, DropdownString: string, InputName: string }
export interface IGeneratorSurveyCheckboxProps { ListField: Array<any>, Selection: Array<any>, InputName: string, CallBack: Function, ShowOnlySelected: boolean }
export interface IGeneratorSurveyRadioButtonsProps { Name: string, Options: Array<any>, Selection: string, CallBack: Function, CSSClass?: string }
export interface IGeneratorSurveyUnitProps { Unit: GeneratorUnitModel, CallBack: Function, Index: number, key: number }
export interface IGeneratorSurveyGasSpecific { CallBack: Function, NestedCallBack: Function }

const mountedStyle = { animation: "inAnimation 250ms ease-in" };
const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards"
};
const defaultUnitList = [new SelectListItem(false, [], false, "", ""), new SelectListItem(false,[],"false","Celsius","Celsius"), new SelectListItem(false,[],true, "Fahrenheit","Fahrenheit"), new SelectListItem(false,[],false,"Unknown","Unknown"), new SelectListItem(false,[],false,"No Known Limit","No Known Limit" )];

interface IGeneratorSurveyProps { SurveyId: string, SubmittedCallback: Function, CancelCallback: Function, DeadlineDate: string, YearRange: string, SurveyKey: string, SuccessfulKey: Boolean }

interface State {
    Survey: SurveyItem;
    Loaded: Boolean;
}

export class GeneratorSurvey extends React.Component<IGeneratorSurveyProps, any>{
    constructor(props) {
        super(props);
        this.state = { Survey: [], Loaded:false};

    }
    public componentDidMount() {
        this.getSurvey();
    }

    private getSurvey() {
        return $.getJSON("/api/GeneratorSurveyReact/GetSurvey?surveyId=" + this.props.SurveyId,
            function (data) {
                for (var i = 0; i < data.genUnits.length; i++) {
                    if (!data.genUnits[i].minimumAmbientTempUnitList) {
                        data.genUnits[i].minimumAmbientTempUnitList = defaultUnitList;
                    }
                    if (!data.surveyKey && this.props.SuccessfulKey) {
                        data.surveyKey = this.props.SurveyKey;
                    }
                }
                this.setState({ Survey: data, Loaded: true});
            }.bind(this));
    }
    public handleChange(event) {
        var fieldName = event.target.name;
        var newValue = event.target.value;
        this.setState(prevState => ({
            Survey: {
                ...prevState.Survey,
                [fieldName]: newValue
            }
        }))
    }
    public handleNestedChange(event) {
        var inputName = event.target.name;
        inputName = inputName.split(".");
        var nestedFieldName = inputName[0];
        var unitName = inputName[1];
        var fieldName = inputName[2];
        var newValue = event.target.value;
        newValue = (newValue == "true" ? true : newValue == "false" ? false : newValue);
        let updatedGenUnitSelection = this.state.Survey.genUnits.map(item => {
            if (item.unitName === unitName) {
                return { ...item, [fieldName]: newValue };
            }
            return item;
        });
        this.setState(prevState => ({
            Survey: {
                ...prevState.Survey,
                [nestedFieldName]: updatedGenUnitSelection
            }
        }))
    }
    public setLBA(event) {
        var selection = event.target.value;
        $.getJSON("/api/GeneratorSurveyReact/GetStationSelectionReact/" + selection,
            function (data) {
                let tempStationList = new Array<SelectListItem>();
                var tempSurvey = this.state.Survey;
                tempStationList = data;
                tempSurvey.lbaSelection = selection;
                tempSurvey.stationList = tempStationList;
                tempSurvey.stationSelection = "";
                tempSurvey.unitNameSelection = [];
                this.setState({ Survey: tempSurvey, Loaded: true });
            }.bind(this));

    }
    public setStation(event) {
        var selection = event.target.value;

        $.getJSON("/api/GeneratorSurveyReact/GetUnitsMetaDataReact?station=" + selection,
            function (data) {
                let tempUnitList = new Array<SelectListItem>();
                var tempSurvey = this.state.Survey;
                tempUnitList = data.unitNameList;
                tempSurvey.stationSelection = selection;
                tempSurvey.unitNameSelection = tempUnitList;
                tempSurvey.genUnits = data.Genunits;
                tempSurvey.isGasSpecific = data.Genunits.some(element => {
                    if (element.fuelType == "Gas" || element.fuelType == "Oil/Gas" || element.fuelType == "Coal/Gas") {
                        return true
                    } else {
                        return false
                    }
                });
                this.setState({ Survey: tempSurvey, Loaded: true });
            }.bind(this));

    }
    public selectUnit(event) {
        var fieldName = "unitNameSelection";
        var isGasSpecificFieldName = "isGasSpecific";
        var genUnitsFieldName = 'genUnits';
        var newValue = event.target.value;
        let updatedUnitNameSelection = this.state.Survey.unitNameSelection.map(item => {
            if (item.id === newValue) {
                return { ...item, isSelected: !item.isSelected };
            }
            return item;
        });
        let updatedGenUnitSelection = this.state.Survey.genUnits.map(unit => {
            if (unit.unitName === newValue) {
                return { ...unit, isSelected: !unit.isSelected };
            }
            return unit;
        });
        this.setState(prevState => ({
            Survey: {
                ...prevState.Survey,
                [fieldName]: updatedUnitNameSelection,
                [genUnitsFieldName]: updatedGenUnitSelection
            }
        }))
    }
    public selectPipeline(event) {
        var fieldName = event.target.name;
        var newValue = event.target.value;
        var visableFieldName = "isVisible";
        let updatedPipelineSelection = this.state.Survey[fieldName].map(item => {
            if (item.id === newValue) {
                return { ...item, isSelected: !item.isSelected };
            }
            return item;
        });
        let updatedFirmTransportPipelineSelection = this.state.Survey.availableFirmTransportPipelines;
        let updatedprimaryUpstreamPipelineList = this.state.Survey.availablePrimaryUpstreamPipelines;
        if (fieldName != "availableFirmTransportPipelines" && fieldName != "availablePrimaryUpstreamPipelines") {
            updatedFirmTransportPipelineSelection = updatedFirmTransportPipelineSelection.map(item => {
                if (item.id === newValue) {
                    return { ...item, isVisible: !item.isVisible };
                }
                return item;
            });
            updatedprimaryUpstreamPipelineList = updatedprimaryUpstreamPipelineList.map(item => {
                if (item.id === newValue) {
                    return { ...item, isVisible: !item.isVisible };
                }
                return item;
            });
        }
        else if (fieldName == "availableFirmTransportPipelines"){
            updatedFirmTransportPipelineSelection = updatedPipelineSelection;
        } else if (fieldName == "availablePrimaryUpstreamPipelines") {
            updatedprimaryUpstreamPipelineList = updatedPipelineSelection;
        }

        this.setState(prevState => ({
            Survey: {
                ...prevState.Survey,
                [fieldName]: updatedPipelineSelection,
                availableFirmTransportPipelines: updatedFirmTransportPipelineSelection,
                availablePrimaryUpstreamPipelines: updatedprimaryUpstreamPipelineList
            }
        }))
    }
    public submitSurvey(event) {
        event.preventDefault();
        var survey = this.state.Survey;
        $.ajax({
            url: '/api/GeneratorSurveyReact/SubmitSurvey',
            type: 'POST',
            data: JSON.stringify(survey),
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: function (data) {
                this.props.SubmittedCallback();
            }.bind(this)
        });
    }
    submitHandler(e) {
        e.preventDefault();
    }
    public render() {
        return (
            <div className='WinterSurveyAsReactComp'>
                <h2 className="green-bg">MISO {this.props.YearRange} Generator Winterization Survey</h2>
                <p className="margin-btm-15">Please complete and submit one survey for each Generation Facility/Station you manage. If more than one Generation Facility is managed by your company, you will need to submit multiple surveys.</p>
                <p className="margin-btm-15"><b>NOTE: All new or updated information will be lost if the Cancel button is clicked or the browser is closed before the survey is submitted.</b></p>
                <p className="margin-btm-15">An example and instructions clarifying the survey format can be found <a data-toggle="modal" data-target="#instructionDialog"><b>here</b></a>.</p>
                <p className="margin-btm-15"><b>Survey Deadline is {this.props.DeadlineDate}.</b></p>
                <p className="margin-btm-20"><span className="red">*</span> indicates a required field</p>
                {
                    this.state.Loaded ?
                        <form onSubmit={this.submitSurvey.bind(this)} >
                            <div className="form-group row">
                                <div className="col-6">
                                    First Name 
                                </div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <input className="form-control" data-val-required="This question is required!" name="firstName" value={this.state.Survey.firstName} onChange={this.handleChange.bind(this)} required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">Last Name</div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <input className="form-control" data-val-required="This question is required!" name="lastName" value={this.state.Survey.lastName} onChange={this.handleChange.bind(this)} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-6">Company</div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <input className="form-control" data-val-required="This question is required!" name="company" value={this.state.Survey.company} onChange={this.handleChange.bind(this)} required />

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">Phone Number</div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <input className="form-control" placeholder="XXX-XXX-XXXX" data-val-required="This question is required!" name="phone" value={this.state.Survey.phone} onChange={this.handleChange.bind(this)} required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">Email</div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <input className="form-control" data-val-required="This question is required!" name="email" value={this.state.Survey.email} onChange={this.handleChange.bind(this)} required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">What LBA is the plant in?</div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <GeneratorSurveyDropdown ListField={this.state.Survey.lbaList} Selection={this.state.Survey.lbaSelection} CallBack={this.setLBA.bind(this)} DropdownString={"Select LBA"} InputName="lbaSelection" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">What Station?</div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <GeneratorSurveyDropdown ListField={this.state.Survey.stationList} Selection={this.state.Survey.stationSelection} CallBack={this.setStation.bind(this)} DropdownString={"Select Station"} InputName="stationSelection" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">Generator/Unit name(s) <a href="#" role="tooltip" title="Please only select the units that make up the gas-fired station you are completing.  (i.e. a Steam Turbine making up part of a Combined Cycle station should be selected as well).">[?]</a></div>
                                <span className="col-1 red" style={{ width: "0%" }}>*</span>
                                <div className="col-5">
                                    <GeneratorSurveyCheckbox ListField={this.state.Survey.unitNameSelection} Selection={this.state.Survey.unitNameSelection} InputName={"UnitNameSelection"} CallBack={this.selectUnit.bind(this)} ShowOnlySelected={false} />
                                </div>
                            </div>
                            <div>
                                <h3 className="light-blue">Units/Generators</h3>
                                <p>Please complete the following for the unit(s) at the Facility</p>
                                {
                                    this.state.Survey.genUnits
                                        .sort((a, b) => a.unitName > b.unitName ? 1 : -1)
                                        .map((unit, i) => (
                                            unit.isSelected ?
                                                (
                                                    (
                                                        <GeneratorSurveyUnit Unit={unit} CallBack={this.handleNestedChange.bind(this)} Index={i} key={i} />
                                                    )
                                                ) : null
                                        ))
                                }
                            </div>
                            {
                                this.state.Survey.isGasSpecific ?
                                    <div>
                                        <h3 className="light-blue">Gas Specific Questions</h3>
                                        <GeneratorSurveyGasSpecific Survey={this.state.Survey} CallBack={this.handleChange.bind(this)} NestedCallBack={this.selectPipeline.bind(this)} />
                                    </div>
                                    : <div></div>
                            }

                            <div className="margin-top-20">
                                <div>If you are finished with this Station, click the Submit button. For additional stations in your portfolio, please complete another survey.</div>
                                <button className="btn btn-primary margin-top-10 margin-right-20" type="submit">Submit</button>
                                <button className="btn btn-default margin-top-10" onClick={() => { this.props.CancelCallback(); }}>Cancel</button>
                            </div>
                        </form>
                        : <div className="winterSurveySpinner text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></div>
                }
                <div id="instructionDialog" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">MISO Generator Weatherization Survey - Instructions</h4>
                            </div>
                            <div className="modal-body" /*style="overflow:auto;"*/>
                                <p>Please complete and submit one survey for each station you manage.  If more than one facility is managed by your company, you will submit multiple surveys.</p>
                                <br />
                                <p><b>Please review the example below clarifying the survey format:</b></p>
                                <br />
                                <p>Bright Energy owns two Generation facilities.  Each facility has multiple generators/units at each site.</p>
                                <br />
                                <div className="genList" style={{ width: "50%" }}/*style="float: left; width: 200px;"*/>
                                    <span /*style="text-align: center"*/>City Generation Station</span>
                                    <ul>
                                        <li>City Gen 1</li>
                                        <li>City Gen 2</li>
                                        <li>City Gen 3</li>
                                    </ul>
                                </div>
                                <div className="genList" style={{ width: "50%" }}/*style="float: left; min-width: 275px;"*/>
                                    <span /*style="text-align: center"*/>Country Generation Station</span>
                                    <ul>
                                        <li>Country Gen 1</li>
                                        <li>Country Gen 2</li>
                                    </ul>
                                </div>
                                <div className="clearBoth" /*style="clear: both;"*/></div>
                                <br />
                                <p>One survey will be completed and submitted for City Generation Station.  A second survey will be completed and submitted for Country Generation Station.</p>
                                <br />
                                <p>After providing detail for City Gen 1, City Gen 2, City Gen 3, the survey would be submitted using the “Submit” button.</p>
                                <br />
                                <p>Bright Energy will then click on the original Survey link again and complete the same process for Country Generation Station (adding detail for Country Gen 1, Country Gen 2).  After submitting this 2nd survey, Bright Energy will have completed the MISO Fuel Survey request. </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}