// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import { InfoBoxWithImageHeader } from "../InfoBox/InfoBoxWithImageHeader";
import * as dateFormatter from '../../dateFormatter';

interface IMISOMattersProps { fullDetailsView: boolean, numberOfBlogs?: number, seeAllLink: string }

interface State {
    Articles: Array<any>;
    FilterArticles: Array<any>;
    FilterCategory: String;
    CategoryList: Array<string>;
    Loading: boolean;
}

export class MISOMatters extends React.Component<IMISOMattersProps, any>{
    constructor(props) {
        super(props);
        this.state = { Articles: [], FilterCategory: "All", CategoryList:[], FilterArticles:[], Loading:true};

    }

    public componentDidMount() {
        this.getArticles();
    }

    private getArticles() {
        return $.getJSON("/api/MISOMatters/GetBlogs?take=" + this.props.numberOfBlogs,
            function (data) {
                this.setState({ Articles: data.articles, CategoryList: data.categoryList, FilterArticles: data.articles, Loading: false });
            }.bind(this));
    }

    public categoryFilter(category) {
        
        var listOfArticles = [];
        if (category == "All") {
            this.setState({ FilterCategory: category, FilterArticles: this.state.Articles });
        } else {
            for (var i = 0; i < this.state.Articles.length; i++) {
                if (this.state.Articles[i].categoryType == category) {
                    listOfArticles.push(this.state.Articles[i]);
                }
            }
            this.setState({ FilterCategory: category, FilterArticles: listOfArticles, Loading:false });
        }

    }

    public getDisplayDate(date) {
        return dateFormatter.DateFormatter.Format(date, "MM/DD/YYYY");
    }
    public renderCatSelector() {
        return (
            <div className="tab-content-block margin-bottom-20">
                <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={() => { this.categoryFilter("All"); }}><a className={(this.state.FilterCategory == "All" ? "nav-link active" : "nav-link")} data-bs-toggle="tab" onClick={() => { this.categoryFilter("All"); }}>All</a></li>
                    {
                        this.state.CategoryList.map((category, i) => {
                            return (
                                <li key={i} className="nav-item">
                                    <a className={(this.state.FilterCategory == category ? "nav-link active" : "nav-link")} data-bs-toggle="tab" onClick={() => { this.categoryFilter(category); }}>{category}</a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }
    public render() {
        if (this.state.Loading) {
            return (<h2><i className="fa fa-spinner text-center w-100 margin-top-10" aria-hidden="true"></i></h2>);
        }
        return (
            <div id="MISOMattersBlock">
                {
                    this.props.fullDetailsView ? 
                        <div className='notifications-filters margin-btm-10'>
                            {this.renderCatSelector()}
                        </div>
                        : ""
                }

                {this.state.FilterArticles.length == 0 ? <div></div>
                    :
                    <div className="row articleRow aggregatorPageRow">
                        {
                            this.state.FilterArticles.map((article, i) => {
                                {
                                    return (
                                        <div key={i} className='col-12 col-md-6 aggregatorSection d-grid margin-btm-20'>
                                            <InfoBoxWithImageHeader key={i} linkUrl={article.linkURL} imageString={article.imageUrl} headerText={article.name} description={article.text} linkText={"Read Article >"} personName={article.mediaContactName} date={article.publishDate} category={article.categoryType} />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}