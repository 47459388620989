// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

interface ITwitterFeedProps { Topic: string, SiteURL: string, ImageString: string, Text: string}

interface State {
    Tweets: Array<any>;
    Loading: boolean;
    User: Object;
}

export class TwitterFeed extends React.Component<ITwitterFeedProps, any>{
    constructor(props) {
        super(props);
        this.state = { Tweets: [], Loading: true, User: []};

    }

    public componentDidMount() {
        this.getTweets();
    }

    private getTweets() {
        let headers = new Headers();

        headers.append
       $.getJSON("/api/TwitterFeed/GetTweets",
            function (data) {
                for (var i = 0; i < data.data.length; i++) {
                    data.data[i].text = data.data[i].text.split(" ");
                    for (var j = 0; j < data.data[i].text.length; j++) {
                        if (data.data[i].text[j].startsWith("https://")) {
                            data.data[i].text[j] = '<a href="' + data.data[i].text[j] + '">' + data.data[i].text[j] + '</a>';
                        }
                    }
                    data.data[i].text = data.data[i].text.join(" ");
                }
                this.setState({ Tweets: data.data, Loading:false, User: data.includes.users[0]});
            }.bind(this));
    }


    public render() {
        return (
            <div className="twitterBlock">
                {/*<h2 className="blue-bg">Latest Tweets</h2>*/}
                {this.state.loading ?
                    <i className="fa fa-spinner" aria-hidden="true"></i> :
                    <div className="tweetArea">
                        <div className="topArea">
                            <span className="topAreaText">Tweets</span>
                            <a href={this.state.User.url} className="followButton margin-left-20"><i className="fa fa-twitter margin-right-5" aria-hidden="true"> </i>Follow @MISO_energy</a>
                        </div>
                        {
                            this.state.Tweets.slice(0, 3).map((tweet, i) => {
                                return (
                                    <div key={i} className="individualTweet">
                                        <div className="tweetHeaderArea">
                                            <div className="tweetImage">
                                                <img src={this.state.User.profile_image_url} />
                                            </div>
                                            <div className="tweetHandleArea">
                                                <div className="twitterName">
                                                    <a href={this.state.User.url} >{this.state.User.name}</a> 
                                                </div>
                                                <div className="twitterHandle">
                                                    @{this.state.User.username}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bodyArea">
                                            <span dangerouslySetInnerHTML={{ __html: tweet.text }}></span>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }
            </div>
            
        )
    }
}